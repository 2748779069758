<template>
<div class="d-flex flex-column">
  <div class="my-12 mx-auto">
    <img src="@/assets/logo.svg" width="160" height="48" />
  </div>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="6">
      <v-form>
        <v-card class="card-shadow pt-10 pb-12">
          <div class="text-center">
            <h1 class="pa-2">Sign in </h1>
          </div>
          <v-card-text class="text-center font-size-root pa-0">
            <p class="pa-2 mb-0">With account</p>
            <v-btn
              id="google-login-button"
              @click="googleLogin()"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-secondary py-3 px-6 rounded-sm ma-2"
            >
              <v-img
                max-width="20"
                max-height="20"
                src="@/assets/img/google.svg"
                class="me-3"
              ></v-img>
              Google login
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</div>
</template>
<script>

import { googleLogin } from "@/data.js";

export default {
  name: "login",
  methods: {
    googleLogin,
  },
  data() {
    return {
      checkbox: false,
    };
  },
};
</script>
